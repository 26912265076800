<template>
  <div>
    <b-row>
      <b-col
        md="12"
      >
        <b-card>
          <b-overlay
            :show="isProcessing"
          >
            <b-row>
              <b-col md="9">
                <h3>{{ service.name }}</h3>
              </b-col>
              <b-col md="3">
                <b-button
                  v-b-tooltip.hover="`Add Secret Key`"
                  variant="primary"
                  class="mb-1 float-right"
                  @click="keyFormModel"
                >
                  <b-spinner
                    v-show="isProcessing"
                    small
                    class="mr-1"
                  />
                  <feather-icon icon="KeyIcon" />Secret Key
                </b-button>
              </b-col>
            </b-row>
            <b-table-simple
              striped
              hover
              responsive
            >
              <b-thead>
                <b-tr>
                  <b-td>Name</b-td>
                  <b-td>Generated On</b-td>
                  <b-td>Expires On</b-td>
                  <b-td>Action</b-td>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="secretKey, index in service.accessKeys"
                  :key="index"
                >
                  <b-td>
                    {{ secretKey?.name ?? 'N/A' }}
                  </b-td>
                  <b-td>
                    {{ secretKey?.generated_on ? humanReadableDate(secretKey?.generated_on, 'date-time'): 'N/A' }}
                  </b-td>
                  <b-td>
                    {{ secretKey?.expires_on ? humanReadableDate(secretKey?.expires_on): 'Never' }}
                    <span class="font-italic">
                      {{ secretKey?.expires_on ? `( ${getDaysDifference(secretKey?.expires_on)} )`: '' }}
                    </span>
                  </b-td>
                  <b-td>
                    <b-button
                      v-b-tooltip.hover="`Delete Secret Key`"
                      variant="primary"
                      class="mb-1 mr-1"
                      @click="editServiceKey(secretKey)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover="`Delete Secret Key`"
                      v-b-modal.service-key-delete-confirm-modal
                      variant="danger"
                      class="mb-1"
                      @click="selectedKey = secretKey.uuid"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>

              <b-tfoot>
                <b-tr>
                  <b-td
                    colspan="4"
                    class="text-center pb-0 pt-2"
                  >
                    No service secret keys found
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      :visible="showModal"
      :title="modalTitle"
      ok-only
      no-close-on-backdrop
      @close="resetAndClose"
      @hide="resetAndClose"
    >
      <validation-observer ref="keyValidation">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Secret Key Name"
                label-for="secret_key_name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Secret Key Name"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="secret_key_name"
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      placeholder="Secret Key Name"
                      name="secret_key_name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!isEdit"
              md="12"
            >
              <b-form-group
                label="Secret Key *"
                label-for="secret_key"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Secret Key"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="secret_key"
                      v-model="form.secret_key"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="secret_key"
                      disabled
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        v-b-tooltip="'Click to Copy Secret Key'"
                        class="cursor-pointer"
                        icon="FileIcon"
                        @click="copyKey"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-warning"><feather-icon
                    icon="AlertCircleIcon"
                    class="mr-1"
                  />Please copy the secret key now, as it is only visible this time</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Expires On"
                label-for="expires_on"
              >
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-datepicker
                    id="expires_on"
                    v-model="form.expires_on"
                    :min="minDate"
                    placeholder="Expires On"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      v-b-tooltip="'Click to reset date'"
                      class="cursor-pointer"
                      icon="RotateCcwIcon"
                      @click="resetDate"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-muted"><feather-icon
                  icon="AlertCircleIcon"
                  class="mr-1"
                />Expires On date can be null. Reset if you want to leave it blank</small>
              </b-form-group>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
      <template #modal-footer="{}">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              type="submit"
              variant="primary"
              block
              @click="!isEdit ? storeSecretKey($event) : updateSecretKey($event)"
            >
              <b-spinner
                v-show="isProcessing"
                small
                class="mr-1"
              />
              {{ modalTitle }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <confirm-modal
      modal="service-key-delete-confirm-modal"
      :title="`Delete Service Key?`"
      :description="`Are you sure you want to delete Service Key?`"
      @confirm="onDeleteModule"
    />
  </div>
</template>
<script setup>
import {
  // computed,
  getCurrentInstance,
  onMounted,
  ref,
  // watch,
  reactive,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow, BCol, BThead, BTbody, BTfoot, BTr, BTd, BCard, BTableSimple, BForm, BFormGroup, BInputGroup,
  BInputGroupAppend, BFormInput, BButton, BModal, BSpinner, VBTooltip, BOverlay, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'

const root = getCurrentInstance().proxy
const { serviceId } = root.$route.params
const keyValidation = ref(null)
const form = reactive({ secret_key: '', expires_on: null })
const showModal = ref(false)
const isProcessing = ref(false)
const modalTitle = ref('')
const selectedKey = ref('')
const service = ref({})
const vBTooltip = VBTooltip
const isEdit = ref(false)

const today = new Date()
today.setDate(today.getDate() + 4) // Add 4 days
const minDate = today.toISOString().split('T')[0]

const generateKey = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  form.secret_key = `sk_${Array.from({ length: 48 }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('')}`
}
const resetDate = () => {
  console.log('here')
  form.expires_on = null
  console.log(form)
}

const keyFormModel = () => {
  modalTitle.value = 'Add Service Keys'
  showModal.value = true
  generateKey()
}

const copyKey = () => {
  navigator.clipboard.writeText(form.secret_key)
    .then(() => {
      root.showSuccess('Secret key copied to clipboard!')
    })
}
const humanReadableDate = (date, format = '') => {
  if (format === 'date-time') {
    return moment(date).utc().format('MMMM D YYYY HH:mm [UTC]')
  }
  return moment(date).utc().format('MMMM D YYYY')
}
const getDaysDifference = expireDate => {
  const from = new Date()
  const days = root.DAYS_DIFF_FROM_TO(from, expireDate)
  if (days > 1) { return `${days} Days left` }
  return `${days} Day left`
}
const getServiceDetail = () => {
  isProcessing.value = true
  useJwt.getServiceKeys(serviceId).then(response => {
    service.value = response.data.service
    isProcessing.value = false
  }).catch(error => {
    root.showErrorMessage(error)
  })
}
const resetAndClose = () => {
  keyValidation.value.reset()
  form.secret_key = ''
  form.name = ''
  form.expires_on = ''
  showModal.value = false
  isProcessing.value = false
  isEdit.value = false
}
const storeSecretKey = event => {
  event.preventDefault()
  keyValidation.value.validate().then(success => {
    if (success) {
      const formData = {
        name: form.name,
        secret_key: form.secret_key,
        expires_on: form.expires_on,
      }
      isProcessing.value = true
      useJwt.storeServiceKeys(serviceId, formData).then(response => {
        resetAndClose()
        getServiceDetail()
        root.showSuccessMessage(response)
        // showModal.value = false
      }).catch(error => {
        root.showErrorMessage(error)
      })
    }
  })
}

const updateSecretKey = event => {
  event.preventDefault()
  keyValidation.value.validate().then(success => {
    if (success) {
      const formData = {
        name: form.name,
        expires_on: form.expires_on,
      }
      isProcessing.value = true
      useJwt.updateServiceKeys(form.id, formData).then(response => {
        resetAndClose()
        getServiceDetail()
        root.showSuccessMessage(response)
        // showModal.value = false
      }).catch(error => {
        root.showErrorMessage(error)
      })
    }
  })
}

const editServiceKey = serviceKey => {
  showModal.value = true
  form.id = serviceKey.uuid
  form.name = serviceKey.name
  form.expires_on = serviceKey.expires_on
  isEdit.value = true
  modalTitle.value = 'Update Service Keys'
}
const onDeleteModule = () => {
  isProcessing.value = true
  useJwt.deleteServiceKeys(selectedKey.value).then(response => {
    getServiceDetail()
    selectedKey.value = ''
    // showModal.value = false
    root.showSuccessMessage(response)
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
  })
}
onMounted(() => {
  getServiceDetail()
})

// export default {
//   components: {
//     BRow,
//     BCol,
//     BThead,
//     BTbody,
//     BTr,
//     BTd,
//     BCard,
//     BTableSimple,
//     BForm,
//     BFormGroup,
//     BInputGroup,
//     BInputGroupAppend,
//     BFormInput,
//     BButton,
//     BModal,
//     BSpinner,
//     BOverlay,
//     ValidationProvider,
//     ValidationObserver,
//   },
//   directives: {
//     'b-tooltip': VBTooltip,
//   },
//   data() {
//     return {
//       serviceId: null,
//       secretKeys: [],
//       isProcessing: false,
//       showModal: false,
//       modalTitle: 'Add Service Keys',
//       form: {},
//     }
//   },
//   created() {
//     // Access the route params in the `created` lifecycle hook
//     this.serviceId = this.$route.params.serviceId
//     this.getServiceDetail()
//   },

//   methods: {
//     keyFormModel() {
//       this.modalTitle = 'Add Service Keys'
//       this.showModal = true
//     },
//     resetAndClose() {
//       this.$refs.keyValidation.reset()
//       this.form.secret_key = ''
//       this.showModal = false
//       this.isProcessing = false
//     },
//     generateKey() {
//       console.log('here')
//     },
//     getServiceDetail() {
//       // const params = { service: this.serviceId }
//       // useJwt.getGateways({ params }).then(res => {
//       //   const gateways = res.data
//       //   console.log(gateways)
//       // })
//     },
//   },
// }
</script>
